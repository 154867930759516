import { createApp } from 'vue';
import router from '@/router';
import App from '@/App.vue';
import store from '@/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

//require('./src/assets/css/style.css')

//import VueElementLoading from "vue-element-loading";

//Vue.component("VueElementLoading", VueElementLoading);

const functionLocation = 'asia-northeast1';

//入力欄が空欄時にエラーが出ないようにする
firebase.firestore().settings({
    ignoreUndefinedProperties: true,
});

createApp(App).use(store).use(router).mount('#app');

let recaptchaScript = document.createElement('script');
recaptchaScript.setAttribute('src', 'https://js.stripe.com/v3/');
document.head.appendChild(recaptchaScript);
