import { createStore } from 'vuex';

export default createStore({
    state: {
        email: 'Hello Vuex!!!',
    },
    mutations: {},
    actions: {},
    modules: {},
});
