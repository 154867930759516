<template ontouchstart="">
    <div id="loading" v-if="is_loading">
        <!--<div class="spinner"></div>-->
    </div>
    <router-view ref="routerView" :footer_height="footerHeight" :country="country" :language_footer="language" :currency_footer="currency" />
    <div id="script_wrap"></div>
</template>

<style>
/* ファイルパスに従って@importntの後にファイルパスを書き込む */
@import './assets/css/style.css';
</style>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useHistoryState, onBackupState } from 'vue-history-state';

import router from './router';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

// ページ内の高さ
const vh = window.innerHeight;

export default {
    router,
    name: 'app',
    data: function () {
        return {
            is_loading: true,
            is_footer: true,
            is_login: false,
            footerHeight: '',
            country: '',
            currency: '',
            language: '',
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                console.log('コンポーネントが変更されました:', this.$refs.routerView);

                if (this.$refs.routerView != undefined) {
                    this.is_loading = false;
                    let route = this.$route;
                    this.setMeta(route);
                    if (this.$route.name == 'success_send_point') {
                        this.is_footer = false;
                    } else if (this.$route.name == 'success_buy_rank') {
                        this.is_footer = false;
                    } else if (this.$route.name == 'login') {
                        this.is_footer = false;
                    } else if (this.$route.name == 'signup') {
                        this.is_footer = false;
                    } else {
                        this.is_footer = true;
                        let client_h = document.getElementById('footer').clientHeight;
                        this.footerHeight = client_h;
                        //console.log(this.footerHeight);
                    }

                    console.log(this.$route.name);
                }
            },
        },
    },
    mounted() {
        this.$route;

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.is_login = true;
            }
        });

        window.addEventListener('pageshow', () => {
            if (window.performance.navigation.type == 2) location.reload();
        });

        fetch('https://ipapi.co/json/')
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                const region = data.country;
                this.country = region;
                //console.log(this.country);
                // 特定されたアクセス地域を利用して処理を行うことができます
                //console.log('地域', region);
                const cookieValue = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('fanpo_language'))
                    .split('=')[1];
                console.log(cookieValue);
            })
            .catch((error) => {
                if (this.country == 'JP') {
                    document.cookie = 'fanpo_language=japanese';
                    document.cookie = 'fanpo_currency=jpy';
                } else {
                    document.cookie = 'fanpo_language=en';
                    document.cookie = 'fanpo_currency=usd';
                }
                const fanpo_language = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('fanpo_language'))
                    .split('=')[1];
                console.log(fanpo_language);

                const fanpo_currency = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('fanpo_currency'))
                    .split('=')[1];
                console.log(fanpo_currency);

                console.log('Error:', error);
            });

        window.addEventListener('beforeunload', () => {
            // ここに処理内容
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    user.getIdTokenResult(true).then((result) => {
                        console.log(result.claims);

                        // 現在の日付情報を取得
                        const after_hour = dayjs(result.claims.exp * 1000)
                            .subtract(1, 'h')
                            .format();

                        if (dayjs().subtract(1, 'm').isAfter(after_hour)) {
                            firebase
                                .auth()
                                .signOut()
                                .then(() => {});
                        } else {
                            //none
                        }
                    });
                }
            });
        });
    },
    methods: {
        setMeta(route) {
            // タイトルを設定
            if (route.meta.title) {
                let setTitle = route.meta.title;
                document.title = setTitle;
            }
            // ディスクリプションを設定
            if (route.meta.desc) {
                let setDesc = route.meta.desc;
                document.querySelector('meta[name="description"]').setAttribute('content', setDesc);
            }
        },
        sign_out() {
            this.is_loading = true;
            firebase
                .auth()
                .signOut()
                .then(() => {
                    window.location.href = 'http://' + location.host + '/login';
                })
                .catch((error) => {
                    this.is_loading = false;
                });
        },
        select_currency() {
            document.cookie = `fanpo_currency=${this.currency}`;
            this.$emit('select_currency_footer');
        },
        select_language() {
            document.cookie = `fanpo_language=${this.language}`;
            this.$emit('select_language_footer');
        },
    },
};
</script>
